<template>
  <div style="background:#f0f0f0; height:100vh;">
    <div class="header">
      <div class="header-title">
        <h1>总排行积分</h1>
        <span
          class="tip"
          @click="popTip"
        ></span>
      </div>
      <span class="points">{{totalPoints}}</span>
      <div class="history-points">
        <span class="history-points-label">历史累计积分 [ 2018-05-01 前 ]</span>
        <span class="history-points-value">{{historyPoints}} 分</span>
      </div>
      <div class="month-points">
        <ul>
          <li>
            <span class="points-label">本期扫码积分</span>
            <span class="points-value">{{currentSeason.codePoints}}</span>
          </li>
          <li>
            <span class="points-label">本期任务积分</span>
            <span class="points-value">{{currentSeason.taskPoints}}</span>
          </li>
          <li>
            <span class="points-label">本期抽奖积分</span>
            <span class="points-value">{{currentSeason.lotteryPoints}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="body">
      <template v-if="totalPoints !== 0">
        <div
          class="monthly-points-list"
          v-for="(flow, index) in pointsFlow"
          :key="index"
        >
          <div
            class="month-header"
            ref="monthHeader"
            :style="{position: isHeaderFixed(index) ? 'fixed' : 'relative'}"
          >
            <h2 class="month-title">{{flow.seasonId | season(currentSeason.id)}}</h2>
            <h2 class="month-points-value">{{flow.totalPoints}} 分</h2>
            <div class="month-points-detail">
              <span>扫码积分：{{flow.codePoints}}</span>
              <span>任务积分：{{flow.taskPoints}}</span>
              <span>抽奖积分：{{flow.lotteryPoints}}</span>
            </div>
            <span
              class="filter"
              v-if="index === 0 || isHeaderFixed(index)"
              @click="doFilter(flow.month)"
            />
          </div>
          <ul class="month-detail-list">
            <li
              v-for="(item, index) in flow.pointsList"
              :key="index"
            >
              <h3 class="point-name">{{item.instructions}}</h3>
              <h3 class="point-value">+{{item.points}}</h3>
              <span class="point-desc">{{item.type | pointsType}}</span>
              <span class="point-time">{{item.createTime}}</span>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { monthPoints } from 'components/bomb-box/index.js'
import { getMyPoints, getMyPointsSummary } from 'api/my-points'
import { ModalHelper } from 'utils'

const seasons = [
  { text: '全部赛期', value: -1 },
  { text: '第一期', value: 0 },
  { text: '第二期', value: 1 },
  { text: '第三期', value: 2 },
  { text: '第四期', value: 3 },
  { text: '第五期', value: 4 },
  { text: '第六期', value: 5 },
  { text: '第七期', value: 6 },
  { text: '第八期', value: 7 },
  { text: '第九期', value: 8 },
  { text: '第十期', value: 9 }
]

export default {
  data () {
    return {
      pointsFlow: [],
      totalPoints: 0,
      historyPoints: 0,
      currentSeason: {
        id: 0,
        codePoints: 0,
        taskPoints: 0,
        lotteryPoints: 0
      },
      seasonCount: 0,
      scrollTop: 0,
      headerFixed: [],
      filterPicker: null
    }
  },
  filters: {
    pointsType (value) {
      switch (value) {
        case 0:
          return '任务'
        case 1:
          return '抽奖'
        case 2:
          return '扫码'
      }
    },
    season (value, currentSeasonId) {
      return value === currentSeasonId ? '本期' : seasons[value + 1].text
    }
  },
  methods: {
    isHeaderFixed (index) {
      return this.headerFixed[index]
    },
    doFilter (month) {
      this.showDatePicker()
    },
    popTip () {
      this.$createDialog({
        showClose: true,
        confirmBtn: {
          active: true,
          disabled: true
        }
      }, monthPoints).show()
    },
    getMyPointsSummary () {
      getMyPointsSummary({}).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }

        this.totalPoints = res.data.data.totalPoints
        this.historyPoints = res.data.data.historyPoints
      })
    },
    getMyPoints (season) {
      getMyPoints({ seasonId: season }).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }

        this.pointsFlow = res.data.data
        this.seasonCount = this.pointsFlow ? this.pointsFlow.length : 0

        this.$nextTick(() => {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        })

        if (this.seasonCount <= 0) {
          return
        }

        if (season >= 0) {
          return
        }

        let flow = this.pointsFlow[0]
        this.currentSeason.id = flow.seasonId
        this.currentSeason.codePoints = flow.codePoints
        this.currentSeason.taskPoints = flow.taskPoints
        this.currentSeason.lotteryPoints = flow.lotteryPoints
      })
    },
    showDatePicker () {
      if (!this.filterPicker) {
        seasons[this.seasonCount].text = '本期'
        this.filterPicker = this.$createPicker({
          title: '请选择',
          data: [seasons.slice(0, this.seasonCount + 1)],
          onSelect: this.selectHandle,
          onChange: (index, selectedIndex) => {
            let options = document.getElementsByClassName('wheel-item')
            options = options || document.getElementsByClassName('cube-picker-wheel-item')
            for (let i = 0; i < options.length; i++) {
              if (i === selectedIndex) {
                options[i].classList.add('active')
              } else {
                options[i].classList.remove('active')
              }
            }
          },
          onCancel: () => {
            ModalHelper.onClose()
            let options = document.getElementsByClassName('wheel-item')
            options = options || document.getElementsByClassName('cube-picker-wheel-item')
            for (let i = 0; i < options.length; i++) {
              options[i].classList.remove('active')
            }
          }
        })

        let options = document.getElementsByClassName('wheel-item')
        options = options || document.getElementsByClassName('cube-picker-wheel-item')
        options[0].classList.add('active')

        let picker = document.getElementsByClassName('cube-picker-choose')
        picker[0].classList.add('season-picker')
      }

      ModalHelper.onOpen()
      this.filterPicker.show()
    },
    selectHandle (selectedVal, selectedIndex, selectedText) {
      ModalHelper.onClose()
      this.getMyPoints(selectedVal[0] === -1 ? null : selectedVal[0])
    }
  },
  mounted () {
    this.getMyPointsSummary()
    this.getMyPoints()

    window.onscroll = () => {
      this.scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop

      let fixed = []
      for (let j = 0; j < this.seasonCount; j++) {
        let count = 0
        for (let i = 0; i < j; i++) {
          if (!this.pointsFlow[i].pointsList) {
            continue
          }

          count += this.pointsFlow[i].pointsList.length
        }
        count = count > 0 ? count - 1 : count
        let ratio = document.body.clientWidth / 750
        let headerPos = ratio * (426 + count * 120)
        fixed[j] = this.pointsFlow[j].pointsList.length > 0 && this.scrollTop > headerPos
      }

      this.$nextTick(() => {
        this.headerFixed = fixed
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~styles/index.scss";

@mixin font-style(
  $height: 33px,
  $lineHeight: 33px,
  $fontSize: 24px,
  $opacity: 0.6
) {
  height: px2rem($height);
  line-height: px2rem($lineHeight);
  font-size: px2rem($fontSize);
  opacity: $opacity;
}

* {
  box-sizing: border-box;
  line-height: px2rem(16px);
  font-size: px2rem(16px);
}

.header {
  width: px2rem(750px);
  height: px2rem(426px);
  line-height: px2rem(33px);
  font-size: px2rem(24px);
  color: #fff;
  background: linear-gradient(
    132.9deg,
    rgba(255, 152, 91, 1),
    rgba(255, 63, 79, 1)
  );

  .header-title {
    display: inline-block;
    width: 100%;
    height: px2rem(33px);
    text-align: center;
    margin-top: px2rem(53px);

    &:before {
      content: "";
      display: inline-block;
      width: 0;
      height: 100%;
      vertical-align: middle;
    }

    h1:first-child {
      display: inline-block;
      vertical-align: middle;
      @include font-style;
    }

    .tip {
      display: inline-block;
      width: px2rem(28px);
      height: px2rem(29px);
      @include font-style($opacity: 1);
      margin-top: px2rem(-3px);
      margin-left: px2rem(6px);
      vertical-align: middle;
      background: url("~images/dow-ceremony/tip.png") center no-repeat;
      background-size: contain;
    }
  }

  .points {
    display: inline-block;
    width: 100%;
    @include font-style(
      $fontSize: 90px,
      $height: 126px,
      $lineHeight: 126px,
      $opacity: 1
    );
    text-align: center;
  }

  .history-points {
    margin-top: px2rem(14px);
    width: 100%;
    text-align: center;
    @include font-style($opacity: 1);

    &-label {
      font-size: px2rem(24px);
      display: inline-block;
    }

    &-value {
      display: inline-block;
      font-size: px2rem(28px);
    }
  }

  .month-points {
    background: rgba(255, 255, 255, 0.06);
    width: 100%;
    height: px2rem(135px);
    margin-top: px2rem(42px);

    li {
      float: left;
      margin-top: px2rem(28px);
      text-align: center;
      width: 33.3333%;
    }

    .points-label {
      display: inline-block;
      width: 100%;
      @include font-style;
    }

    .points-value {
      margin-top: px2rem(3px);
      @include font-style(
        $fontSize: 32px,
        $height: 45px,
        $lineHeight: 45px,
        $opacity: 1
      );
    }
  }
}

.month-header {
  position: relative;
  top: 0;
  display: inline-block;
  width: 100%;
  height: px2rem(110px);
  background: #f0f0f0;
  z-index: 1;

  .month-title,
  .month-points-value {
    display: inline-block;
    margin-top: px2rem(15px);
    margin-left: px2rem(30px);
    color: #363636;
    @include font-style(
      $fontSize: 32px,
      $height: 45px,
      $lineHeight: 45px,
      $opacity: 1
    );
  }

  .month-points-value {
    margin-left: px2rem(20px);
    color: #fc4c5a;
  }

  .month-points-detail {
    width: 100%;
    span {
      float: left;
      @include font-style(
        $fontSize: 22px,
        $height: 30px,
        $lineHeight: 30px,
        $opacity: 1
      );
      color: #999999;
      margin-top: px2rem(4px);
      margin-left: px2rem(20px);
    }

    span:first-child {
      margin-left: px2rem(30px);
    }
  }

  .filter {
    position: absolute;
    right: px2rem(30px);
    top: px2rem(35px);
    width: px2rem(38px);
    height: px2rem(40px);
    background: url("~images/dow-ceremony/calendar.png") center no-repeat;
    background-size: contain;
  }
}

.month-detail-list {
  padding-left: px2rem(30px);
  background: #fff;
  li {
    position: relative;
    height: px2rem(120px);
    border-bottom: solid 1px #e4e4e4;
  }

  li:last-child {
    border-bottom: none;
  }

  .point {
    &-name,
    &-value {
      position: absolute;
      top: px2rem(24px);
      @include font-style(
        $fontSize: 28px,
        $height: 40px,
        $lineHeight: 40px,
        $opacity: 1
      );
    }

    &-desc,
    &-time {
      position: absolute;
      bottom: px2rem(20px);
      color: #999999;
      @include font-style($opacity: 1);
    }

    &-value,
    &-time {
      right: px2rem(14px);
    }
  }
}
</style>
